import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const RoutesPrivate = ({ loggedIn, component: Component, ...rest }) => (
    
    <Route {...rest} render={(props)=>(
        loggedIn ?(
            <Component {...props} /> 
        ):( 
            // <Redirect to={"/login"}/>
            <Redirect to={"/"}/>
        )   
    )}/>
)


const mapsState = (props) => {
    return {
        loggedIn: props.user.loggedIn
    }
}


export default connect(mapsState)(RoutesPrivate)