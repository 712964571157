let initialState = {
    fetching: 0,
    drawer: false,
    options: false
}


const TOOGLE_DRAWER = "TOOGLE_DRAWER"
const TOOGLE_OPTIONS = "TOOGLE_OPTIONS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TOOGLE_DRAWER: {
            return { ...state, drawer: action.payload }
        }
        case TOOGLE_OPTIONS: {
            return { ...state, options: action.payload }
        }
        default:
            return state;
    }
}

export let onToggleDrawable = () => (dispatch, getState) => {

    dispatch({
        type: TOOGLE_DRAWER,
        payload: !getState().alert.drawer
    })
}
export let onToggleOptions = () => (dispatch, getState) => {

    dispatch({
        type: TOOGLE_OPTIONS,
        payload: !getState().alert.options
    })
}