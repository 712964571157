import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { onToggleOptions } from '../redux/alertDuck';

const OptionsBranch = ({ onToggleOptions, options, branch_office }) => {

    const onHide = () => {
        onToggleOptions()
    }

    return (
        <div className={`container-options-branch ${options === true ? '' : 'active-branch'}`} onClick={onHide}>
            <div className={`container-options-branch-content ${options === true ? '' : 'active-branch'}`}>
                <Container className='content-branch'>
                    <Row className='mt-2'>
                        <Col>
                            {branch_office.map((b, k) =>
                                <Row className='my-2 border-bottom' key={k}>
                                    <Col>{b.title}</Col>
                                    <Col>{b.address}</Col>
                                </Row>)}
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    )
}

const mapsState = (props) => {
    let { alert, branch_office } = props

    return {
        options: alert.options,
        branch_office: branch_office.data
    }
}

export default connect(mapsState, { onToggleOptions })(OptionsBranch);