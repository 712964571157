import axios from 'axios'
import { getHeaders, removeSession, saveStorage, validateInput } from '../utils'

const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    loggedIn: false,
    errors: {},
    data: {}
}


const RESTORE_SESSION = "RESTORE_SESSION"
const RESTORE_SESSION_SUCCESS = "RESTORE_SESSION_SUCCESS"
const RESTORE_SESSION_ERROR = "RESTORE_SESSION_ERROR"
const LOGOUT = "LOGOUT"
const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"
const LOGIN_ERROR = "LOGIN_ERROR"

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case RESTORE_SESSION: {
            return { ...state, fetching: 1 }
        }
        case RESTORE_SESSION_SUCCESS: {
            return { ...state, fetching: 2, ...action.payload }
        }
        case RESTORE_SESSION_ERROR: {
            return { ...state, fetching: 3, fetching_visit: false }
        }
        case LOGIN: {
            return { ...state, fetching: 1 }
        }
        case LOGIN_SUCCESS: {
            return { ...state, fetching: 2, ...action.payload }
        }
        case LOGIN_ERROR: {
            return { ...state, fetching: 3, errors: action.payload }
        }

        default:
            return state;
    }
}


export let onLogin = (form) => dispatch => {

    dispatch({
        type: LOGIN
    })
    let rules = [
        { field: "email_login", msg: "El numero de telefono o email es requerido", },
        { field: "password_login", msg: "El password es requerido" },
    ];


    let validate = validateInput(form, rules);


    if (validate !== null) {
        dispatch({
            type: LOGIN_ERROR,
            payload: validate,
        })
        return;
    }

    let url = `${API_URL}/api/login`;

    console.log('url', url);

    // axios

    axios.post(url, form, {
        headers: getHeaders()
    }).then(resp => {
        let { data } = resp

        data.loggedIn = true;
        let storage = JSON.parse(localStorage.storage)
        storage.access_token = data.access_token
        storage.user = data.user;
        storage.loggedIn = true;


        localStorage.storage = JSON.stringify(storage);

        console.log('data', data);


        dispatch({
            type: LOGIN_SUCCESS,
            payload: data,
            loggedIn: true
        })

    }).catch(err => {
        let { status, data } = err.response
        console.log('err.response', err.response);

        if (status === 400) {

            console.log('data.errors', data.errors);


            dispatch({
                type: LOGIN_ERROR,
                payload: data.errors,
            })
            return;
        }
    })


    // fetch(url, {
    //     method: "POST",
    //     body: JSON.stringify(form),
    //     headers: getHeaders(),
    // }).then(resp => resp.json()).then(resp => {

    //     if (resp.ok === false) {
    //         dispatch({
    //             type: LOGIN_ERROR,
    //             payload: resp.errors
    //         })
    //     } else {
    //         resp.loggedIn = true;
    //         let storage = JSON.parse(localStorage.storage)
    //         storage.access_token = resp.access_token
    //         storage.user = resp.user;
    //         storage.loggedIn = true;


    //         localStorage.storage = JSON.stringify(storage);

    //         dispatch({
    //             type: LOGIN_SUCCESS,
    //             payload: resp,
    //             loggedIn: true
    //         })
    //     }
    // })

}

export let restoreSessionAction = () => dispatch => {
    dispatch({
        type: RESTORE_SESSION
    })

    let storage = localStorage.getItem("storage")

    storage = JSON.parse(storage)



    if (storage) {
        dispatch({
            type: RESTORE_SESSION_SUCCESS,
            payload: storage
        })
    } else {
        dispatch({
            type: RESTORE_SESSION_ERROR,
            payload: storage
        })
    }
}


export let logOutAction = () => dispatch => {
    removeSession()


    dispatch({
        type: LOGOUT,
    })
}

