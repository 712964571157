import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { onLogin } from '../../redux/userDuck'

const LoginPage = ({ errors, onLogin, fetching }) => {

    const [form, setForm] = useState({ email_login: '', password_login: '' })

    const history=useHistory()

    useEffect(() => {
        if (fetching === 2) {
            history.push("/")
        }
    }, [fetching])

    const onSubmit = (e) => {
        e.preventDefault();
        onLogin(form)
    }


    const updateInput = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Container fluid>
            <Row className='my-5'>
                <Col>
                    <h2 className='text-center'>INICIO SESIÓN</h2>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Form onSubmit={onSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Correo y/o Celular</Form.Label>
                                            <Form.Control type="text" placeholder="Enter email" name="email_login" onChange={updateInput} value={form.email_login} />
                                            {errors.email_login !== undefined ? (<label className="text-danger ">{errors.email_login}</label>) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" name="password_login" placeholder="ingresa tu clave" onChange={updateInput} value={form.password_login} />
                                            {errors.password_login !== undefined ? (<label className="text-danger ">{errors.password_login}</label>) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button variant="primary" type="submit">
                                            Iniciar
                                        </Button>
                                    </Col>
                                    <Col className='d-flex align-items-center'>
                                        <Link className='pl-5 ml-5' to="/registrate">Registrate</Link>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>

    )
}

const mapsState = (props) => {
    let { user } = props
    return {
        errors: user.errors,
        fetching: user.fetching
    }
}

export default connect(mapsState, { onLogin })(LoginPage);